@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Bungee+Inline&family=Faster+One&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "resta";
  src: url("./fonts/RestaDisplayFont.ttf");
}

@font-face {
  font-family: "qinzy";
  src: url("./fonts/QinzyRough.ttf");
}

@font-face {
  font-family: "reglisse";
  src: url("./fonts/Reglisse.otf");
}

@font-face {
  font-family: "fmpoint";
  src: url("./fonts/FMPointifax-A.otf");
}

/* h2, h3 {
  font-family: "Bungee Inline", sans-serif;
  font-weight: 400;
  font-style: normal
} */

* {
  font-family: "Audiowide";
}

